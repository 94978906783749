/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/pink-bluegrey.css';
@import 'firebaseui/dist/firebaseui.css';
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-h1, .mat-h2, .mat-h3 {
  text-align: center;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87) !important;
}

.container {
  margin: 0 10px 0 10px;
}

.spacer {
  flex: 1 1 auto;
}

h4 {
  font-size: 12px !important;
  color: white;
}

.cdk-drag-preview {
  color: rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 5px 5px 10px black, -2px -2px 15px rgba(255, 255, 255, 0.2);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cardlist-box:last-child {
  border: none;
}

.cardlist-list.cdk-drop-list-dragging .cardlist-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

footer {
  margin: 0;
  padding: 15px;
  text-align: center;
  justify-content: center;
  font-size: 60%;
}

footer p {
  padding: 0;
}

.mat-h1,
.mat-h2,
.mat-h3 {
  text-align: center;
}

.mat-mdc-standard-chip {
  padding-left: 15px;
}

.mat-mdc-select-panel {
  max-height: 50vh !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  min-width: 80px !important;
}

.mat-paginator {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgb(33, 33, 33);
}

.mat-paginator-container {
  max-height: 40px;
  min-height: 25px;
}

.mat-paginator-page-size {
  max-height: 40px;
  align-items: center !important;
}

.mat-paginator-page-size-select {
  margin: 0 5px !important;
  max-height: 42px;
}

.mat-header-row {
  min-height: 45px;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgb(33, 33, 33);
}

.mat-mdc-menu-item {
  max-height: 35px;
  line-height: 35px !important;
  font-size: 14px !important;
}

.mat-mdc-menu-content {
  font-size: 14px !important;
}

.mat-mdc-menu-item .mat-mdc-icon {
  height: 12px;
  line-height: 10px;
}

.mat-header-cell {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 110%;
}

.mat-mdc-row .mat-mdc-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  cursor: pointer;
}

.mdc-list-item__primary-text {
  font-size: 14px !important;
}

.mat-mdc-menu-item {
  min-height: 35px !important;
}

.mat-mdc-cell:hover {
  overflow: visible;
}

.mdc-fab--mini {
  min-height: 30px !important;
  max-height: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
}

mat-row {
  min-height: 35px !important;
  max-height: 35px !important;
}

mat-row:nth-child(even) {
  background-color: rgb(60, 60, 60);
}

mat-row:nth-child(odd) {
  background-color: rgb(66, 66, 66);
}

.mat-cell {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.mat-footer-row {
  min-height: 45px;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgb(33, 33, 33);
  font-size: 12px;
}

.mat-footer-cell {
  color: rgba(255, 255, 255, 0.8);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.gap-0 {
  gap: 0px 0px;
}